import React from 'react'
import park from "../images/park.jpeg";


function About() {
  return (
    <div>
        
        <div className='album py-0'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-7 mt-3">
                        
                             
                                <div className="card-body">
                                    <div className='textcolor'>
                                    <h2 className="card-title mb-3"><b>About us</b></h2>
                                    </div>
                                    <h6 className="card-text justifytextname mb-2" ><b>Aajadhya Kothi Hill Resort</b> is one of the heavenly places to stay in Purulia district at Sitarampur village. The village finds its mention in ancient texts. Its name itself speaks of Rama and Sita's sojourn during their exile. The village still bears the remnants of their presence, with local tales and landmarks like the "Sita Kund" (pond) adding to its historical allure. Ajodhya Hills, nestled in the verdant embrace of West Bengal's Purulia district, beckons travellers seeking an escape into nature's serenity. The serene ambience of the neighboring sites will definitely fascinate you. So, pack your bags, lace up your shoes, and get ready to discover the magic of Aajadhya Kothi Hill Resort.</h6>
                                  
                                    <a href="tel:  7601807895" className="contactbutton">+91 760180 7895</a>

                                    <a href="tel:  7001763128" className="contactbutton">+91 700176 3128</a>
                                    
                                </div>
                            
                        </div>
                        <div className="col-md-5 mt-3">
                           
                                <img src={park} className="card-img-top" alt="park"/>
                                <div className="card-body">
                                
                                </div>
                           
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default About