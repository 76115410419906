import React from 'react'
import Menu from './Menu'
import Navbar from '../inc/Navbar'

function Menupage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Menu/>
    </div>
  )
}

export default Menupage