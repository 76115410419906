import React from 'react'

function Terms() {
    return (
        <div>
            <div className="album py-0">
                <div className="container">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Terms and conditions
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">

                                    <h6 className='mb-2'>✓ All guests must carry valid photo ID's (Passport/Driving License/Voter ID Card). PAN Card is not accepted at many places as a valid photo ID.</h6>
                                    <h6>✓ Check-in and check-out times at hotels would be as per hotel policies. These are generally pre-decided as per operation schedules.</h6>
                                    <h6>✓ Standard check-in time is 10 AM, and the standard check-out time is 10 AM. Early check-in and late check-out requests are subject to availability and may also attract an additional fee at the property's discretion.</h6>
                                    <h6>✓ If group behaviour is deemed unfit at the property, the resort Property Manager, upon subjective evaluation, retains the full right to take required action, which may also result in an on-spot cancellation without refunds.</h6>
                                    <h6>✓ Children below 18 are only permitted with guardians.
                                    </h6>
                                    <h6>✓ We only accept a government ID as valid identification
                                    </h6>
                                    <h6>✓ Do not smoke inside the tent.</h6>
                                    <h6>✓ You will be charged for any damage or harm to the property caused by you or by your guests</h6>
                                    <h6>✓ Guests are responsible for their own safety and the safety of their belongings during their stay or trip.</h6>
                                    <h6>✓ Not allowed loud music.</h6>
                                    <h6>✓ USE DUSTBIN.</h6>
                                    <h6>✓ ALWAYS TAKE A GUIDE.</h6>
                                    <h6>✓ RETURN BETWEEN 7 PM AND CONSULT WITH CAMP </h6>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Manager
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <h6 className='mb-3'>All are requested to return to resort by 6 pm and If there is no activity (Like a night walk) from us, please do not go outside the resort premises and consult with the resort manager to avoid any uncertainty.</h6>
                                    <h6>✓ Nearest super specialty hospital in Purulia city.</h6>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Conditions against cancellation
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <h5> Cancellation charges are like: -</h5>
                                    <br />
                                    <h6>✓ 0 - 5 days NO REFUND     </h6>
                                    <h6>✓ 6 - 13 days 40% per advance refund</h6>
                                    <h6>✓ 14 and above 80% Per advance refund</h6>

                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <p>If you want to cancel your booking due to any Natural Disaster or Due to harsh weather conditions. The booking amount is 100% refundable if a cancellation is made 25 days prior to the Commencement or Check-In date. For cancellations made within 25 days from the date of trip Commencement or Check-In.  In case you wish to cancel or rescheduling your plan please  Call <a href='tel: 7601807895'>7601807895</a> // <a href='tel: 7001763128'> 7001763128</a></p>
                </div>
            </div>


        </div>
    )
}

export default Terms