import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'

import "./Menu.css";
import ReactWhatsapp from "react-whatsapp";
// import { Typewriter } from 'react-simple-typewriter' 
import { 
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
   FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,

   LinkedinShareButton,
   LinkedinIcon,
   TwitterIcon,
}  from "react-share";



function Menu() {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(51);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [menu, setMenu] = useState([])
      const navigate = useNavigate()
  
      const menuCollectionRef = collection(db, "MENU");
      useEffect(() => {
  
          const getMenu = async () => {
              const data = await getDocs(menuCollectionRef);
              setMenu(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getMenu()
      }, [])
    return (
      
<>
<center>
<div className='textcolor'>
<h2><b>Aajadhya Kothi Hill Resort</b></h2>
<h6>Sitarampur, Arsha, Purulia</h6>
</div>
</center>
<h1 style={{color:"darkBlue"}} ><b><center>Our Menu</center></b></h1>
<br/>

{/* <h2 className="card-text mb-2" style={{paddingTop: "1rem", margin: "auto 0", color: "Gray", textAlign: "center"}}> 
<b> Search <span style={{color: "darkBlue", fontWeight: "bold", marginleft: '10px'}}>
    <Typewriter 
    loop
    cursor
    cursorStyle="_"
    typeSpeed={60}
    deleteSpeed={90}
    delaySpeed={1000}
    words={[ "Bala", "M. B. Dhar and Son Jewellers", "Best Jewellery Collections"]}
    />
</span>
</b></h2> */}
<div className="album py-1">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-2">
        
        <div className="container">

            <div className="row">
            {menu.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.name.toLowerCase().includes(searchTerm.toLowerCase()) 

      
       
        
      ){
        return val;
      }

    }).slice(pagination.start, pagination.end).map((aajadhyakothihillresort) => { return (
            <div className="col-md-6" >
                        <div className="card mb-4 border-White box shadow">
                            <div className="imagesc">
                       
                        <div style={{color:"Darkblue"}} className="card-header"><b><center>  {aajadhyakothihillresort.name}</center> </b></div>

                            <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                
                                <h6 className="card-text mb-2"> <b>{aajadhyakothihillresort.title}</b></h6>
                                
                                
                                 
                                < h5 style={{color: "darkBlue"}}className="card-text"> {aajadhyakothihillresort.price} </h5>
                              
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-2">
                                
                                <h6 className="card-text mb-2"> <b>{aajadhyakothihillresort.title2}</b></h6>
                                
                            
                                < h5 style={{color: "darkBlue"}}className="card-text"> {aajadhyakothihillresort.price2} </h5>
                              
                                </div>
                                 <div className="d-flex justify-content-between align-items-center mb-2">
                                
                                <h6 className="card-text mb-2"> <b>{aajadhyakothihillresort.title3}</b></h6>
                                
                            
                                < h5 style={{color: "darkBlue"}}className="card-text"> {aajadhyakothihillresort.price3} </h5>
                              
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-2">
                                
                                <h6 className="card-text mb-2"> <b>{aajadhyakothihillresort.title4}</b></h6>
                                
                            
                                < h5 style={{color: "darkBlue"}}className="card-text"> {aajadhyakothihillresort.price4} </h5>
                              
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-2">
                                
                                <h6 className="card-text mb-2"> <b>{aajadhyakothihillresort.title5}</b></h6>
                                
                            
                                < h5 style={{color: "darkBlue"}}className="card-text"> {aajadhyakothihillresort.price5} </h5>
                              
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-2">
                                
                                <h6 className="card-text mb-2"> <b>{aajadhyakothihillresort.title6}</b></h6>
                                
                            
                                < h5 style={{color: "darkBlue"}}className="card-text"> {aajadhyakothihillresort.price6} </h5>
                              
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-2">
                                
                                <h6 className="card-text mb-2"> <b>{aajadhyakothihillresort.title7}</b></h6>
                                < h5 style={{color: "darkBlue"}}className="card-text"> {aajadhyakothihillresort.price7} </h5>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-2">
                                
                                <h6 className="card-text mb-2"> <b>{aajadhyakothihillresort.title8}</b></h6>
                                < h5 style={{color: "darkBlue"}}className="card-text"> {aajadhyakothihillresort.price8} </h5>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-2">
                                
                                <h6 className="card-text mb-2"> <b>{aajadhyakothihillresort.title9}</b></h6>
                                < h5 style={{color: "darkBlue"}}className="card-text"> {aajadhyakothihillresort.price9} </h5>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-2">
                                
                                <h6 className="card-text mb-2"> <b>{aajadhyakothihillresort.title10}</b></h6>
                                < h5 style={{color: "darkBlue"}}className="card-text"> {aajadhyakothihillresort.price10} </h5>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-2">
                                
                                <h6 className="card-text mb-2"> <b>{aajadhyakothihillresort.title11}</b></h6>
                                < h5 style={{color: "darkBlue"}}className="card-text"> {aajadhyakothihillresort.price11} </h5>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-2">
                                
                                <h6 className="card-text mb-2"> <b>{aajadhyakothihillresort.title12}</b></h6>
                                < h5 style={{color: "darkBlue"}}className="card-text"> {aajadhyakothihillresort.price12} </h5>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-2">
                                
                                <h6 className="card-text mb-2"> <b>{aajadhyakothihillresort.title13}</b><p style={{color:"darkBlue"}} className="card-text mb-2"> <small> {aajadhyakothihillresort.p13}</small></p></h6>
                                < h5 style={{color: "darkBlue"}}className="card-text"> {aajadhyakothihillresort.price13} </h5>
                                </div>

                              

         
                              

                             
                                
                                <div className="d-flex justify-content-between align-items-center mb-2">
                              
                              <a href="tel: +91  7601807895" className="buttonmenu btn-sm ">Call +91  7601807895</a>
                              <ReactWhatsapp number="+91 7001763128" class="btn btn-success btn-sm" message="I am Interested"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
</svg> +91  7001763128</ReactWhatsapp>
                          </div>
                                <button type="button" className="btn btn btn-dark btn-sm me-1">
                                        Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>  < FacebookShareButton url="https://www.aajadhyakothihillresort.com/Menupage" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.aajadhyakothihillresort.com/Menupage" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.aajadhyakothihillresort.com/Menupage" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.aajadhyakothihillresort.com/Menupage" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.aajadhyakothihillresort.com/Menupage" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
>
 <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
             


                            </div>
                            </div>
                           
</>
        
        );
    }
    
    export default Menu;


