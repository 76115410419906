import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Aboutpage from './components/pages/Aboutpage';
import Contacts from './components/pages/Contacts';
import PremiumDeluxe from './components/pages/PremiumDeluxe';
import Mudroom from './components/pages/Mudroom';
import Gallerypage from './components/pages/Gallerypage';
import Termspage from './components/pages/Termspage';
import Menupage from './components/pages/Menupage';

function App() {
  return (
    <Router>
   
    <div>
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/about-us" element={<Aboutpage/>}/>
      <Route axact path="/Contacts" element={<Contacts/>}/>
      <Route axact path="/Premium-deluxe-room" element={<PremiumDeluxe/>}/>
      <Route axact path="/super-mudroom" element={<Mudroom/>}/>
      <Route axact path="/gallery-page" element={<Gallerypage/>}/>
      <Route axact path="/terms" element={<Termspage/>}/>
      <Route axact path="/Menupage" element={<Menupage/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
