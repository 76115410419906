import React from 'react'
import Navbar from '../inc/Navbar'
import Terms from './Terms'

function Termspage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="album py-0">
        <div className="container">
            <center><h2 className='mb-3'><b>Term & Conditions</b></h2></center>
            </div>
            </div>
        <Terms/>
        <br/>
        <br/>
    </div>
  )
}

export default Termspage