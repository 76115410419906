import React from 'react'
import Navbar from '../inc/Navbar'
import About from './About'
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
function Aboutpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="maroon"/>
        <div className="puruliaresort">
                <div className="album py-1">

                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        <About/>
        <br/>
        <br/>
    </div>
  )
}

export default Aboutpage