import React, { useState, useEffect }  from 'react';

import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'


function Smudroom () {
   const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [duluxslide, setDuluxslide] = useState([])
      const navigate = useNavigate()
  
      const duluxslideCollectionRef = collection(db, "SuperMudroom");
      useEffect(() => {
  
          const getDuluxslide = async () => {
              const data = await getDocs(duluxslideCollectionRef);
              setDuluxslide(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getDuluxslide()
      }, [])
  return (
    <div>

{duluxslide.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } {
        return val;
      }
    }).slice(pagination.start, pagination.end).map((aajadhyakothihillresort) => { return (
       
       
                       
                            <div className="card-body">
                                   
     
  
                                
                               
                                   <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="10000">
      <img src={aajadhyakothihillresort.slider1} className="d-block w-100" alt="..."/>
      
    </div>
    <div className="carousel-item" data-bs-interval="2000">
      <img src={aajadhyakothihillresort.slider2} className="d-block w-100" alt="..."/>
      
    </div>
    <div className="carousel-item">
      <img src={aajadhyakothihillresort.slider3} className="d-block w-100" alt="..."/>
      
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>                        

                               
                    
                              
                            </div>
                           
                                )})} 
                            
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={duluxslide.length}
                /> */}
      
    </div>
  )
}

export default Smudroom

 
