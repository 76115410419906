
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyDn37_mRhHbWKo1x_vfRN-FSNNlkbwZmu8",
  authDomain: "aajadhya-kothihillresort.firebaseapp.com",
  projectId: "aajadhya-kothihillresort",
  storageBucket: "aajadhya-kothihillresort.firebasestorage.app",
  messagingSenderId: "726858734104",
  appId: "1:726858734104:web:8eb53dfec40bbfca83c16d",
  measurementId: "G-MJLFG1M2GD"

};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;